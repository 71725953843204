import React from 'react';
import classNames from 'classnames';

const Faq = () => {

    const outerClasses = classNames(
        'section faq section-faq container-sm mt-8' 
      );

    return(
        <section className="section faq">
            <div className="container">
                <div className="section-faq">
                    <h2 style={{textAlign: "center", marginBottom: "2em"}} className="mt-8">Frequently Asked Questions</h2>

                    <div>
                        <h4 style={{color: "#C1001F"}}>What is Go&Park?</h4>
                        <p>Go&Park is a revolutionary reservation system that allows you to reserve a premium spot, fifteen minutes or even up to
                            six months in advance, just using an app on your phone. You can reserve in two ways – with the “PARK NOW” button if
                            you are within, or with the “MAKE FUTURE RESERVATION” if you are going to arrive later or on another day. When you are
                            in front of your assigned spot, just click “LET ME IN” on your app and the Go&Park device will lower the arm and you can
                            park like you own the place!</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Why is this different than Valet?</h4>
                        <p>With Go&Park, you park yourself in the best available premium spot, keep your keys, stay as long as you want and leave
                            immediately when you want, no waiting and no tip required. Also, you can easily take your purchases to your car and
                            continue shopping.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Where can I download the app?</h4>
                        <p>You can find the links for IOS and Android at www.goandpark.eu or simply access the Go&Park app on the Apple app
                            store or Android’s Google Play.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>How do I sign up/register, what information do I need?</h4>
                        <p>You can quickly register using Facebook or with Email by simply entering your name, email and a password.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Do I need to use a credit card?</h4>
                        <p>Your first use is free using the promo code “GO&PARK20 for 30 min”. After your first free use, a credit card is required. A
                            scanner is available within the app to help you with the process.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Is there a fee for using GO&Park?</h4>
                        <p>Yes, with Go&Park you can reserve a premium spot and access it for a small convenience fee. The fee for each the
                            location is clearly displayed in the app, so you know exactly how much you will be charged.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>What other forms of payments are available?</h4>
                        <p>Visa, MasterCard,American Express credit cards are accepted at this time. Other payment methods will be added in the
                            future.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Does your app show all available parking in the area?</h4>
                        <p>Yes, the closest will show first.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>How do I get directions to the parking spot?</h4>
                        <p>When you select the location, push the yellow “Directions” button on the left of the image and it will take you to your
                            preferred map in the app. Once you arrive to the location, there’s a “Section Diagram” button. Each location is clearly
                            identified with signs to make it easy finding your assigned spot. Each spot number is clearly identified on the wall or on a
                            sign post.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>How do I know if there are any spots available?</h4>
                        <p>The list of locations in the app show how many spots are available for each location.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>How far in advance can I make a reservation?</h4>
                        <p>Up to six months.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>I won’t make my reservation time – can I add more time?</h4>
                        <p>Yes, go to your reservation (calendar icon in top right corner) and tap the “Running Late, ADD 15 min” button, for one
                            time extension. Only one time extension is available per reservation. A fee may apply to extend for an additional 15
                            minutes.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Can I cancel a reservation?</h4>
                        <p>Yes, go to your reservation and tap the “CANCEL PARKING” link on the bottom.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Can I try it?</h4>
                        <p>Yes, use Promo Code “GO&PARK 20” for a free use for the first 30 min.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Can I give a Go&park reservation as a gift to someone else?</h4>
                        <p>You can make a reservation and when the person is there, you can tap “Let me in” on your phone and the arm will go
                            down for that user”. When the person leaves, the event will be charged to your credit card. We are going to introduce
                            gift coupon codes in the near future.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Will I know where I left my car using Go&Park?</h4>
                        <p>Yes, just open your reservation and it will show you the location and specific spot where you parked.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>How do I reserve a spot?</h4>
                        <p>There are two ways – if you are there or are within 15 min of the location, tap “Park Now” and you will have 15 minutes
                            which you can extend for 15 additional minutes (and additional rate may apply). You can also tap “Make a Future
                            Reservation” for later today or any other date up to 6 months.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>How long do I have to park my car?</h4>
                        <p>When you tap “Let me in” you have 1 minute to enter the space for the first time, and 2 minutes to properly align your
                            vehicle. You can back up and get back in the space, to properly align your car within those 2 minutes. If you tap “Let me
                            In,” the arm will lower to give you access to the space. If a minute goes by and no one shows up, the arm will go up again
                            and you will have the remaining of your 15 minutes to arrive at your spot and tap “Let Me In” again.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>What happens if I don’t show up to my reservation?</h4>
                        <p>Your reservation will expire, and the space will be available for another user. You can make another reservation, but be
                            sure you don’t let 2 reservations expire in a day – if you do, the system will block you for 24 hours. Remember these are
                            premium spots, we want them to be available for everyone. A cancellation fee may be charged at some locations.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>When am I charged?</h4>
                        <p>When you leave. When you reserve, we put a hold on your card. When you leave, the final amount is computed and the
                            difference is charged to your card.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Do I need Wi-Fi or Bluetooth?</h4>
                        <p>No, you only need your mobile network connection to use the app. If a Wi-Fi connection is available at the site, you can
                            use it to access the app.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Can my spot be assigned to someone else?</h4>
                        <p>No, but in case you want to change your spot (maybe it’s too tight), simply click the “Switch Space” button in your
                            reservation, and the next best spot will be assigned to you.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>How long can I park in a spot?</h4>
                        <p>As long as you want. You will be charged after you leave, based on the rates at that location.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Will I get a receipt?</h4>
                        <p>Yes, you will receive an email receipt with complete details, including where you parked, how long you parked, rates, time
                            and date, etc.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Can I see my parking history?</h4>
                        <p>Yes, go to the calendar icon on the top right corner. There you can find your current reservations as well as your previous
                            ones, by tapping “History” or “Current”</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>How do I change my account information like credit card and password?</h4>
                        <p>Tap the menu icon on the left upper corner and select “My Account” to view and edit your account info.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>I’m having trouble setting up my account – who do I call?</h4>
                        <p>Go to “Settings” on the main menu and tap “Send us feedback”. Let us know your issue and your phone number in case
                            you want us to call you.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>Can this damage my car?</h4>
                        <p>No. When you have made a reservation and tap “Let me in” the arm folds for you and it will stay down until you leave. The
                        arm will only go back up once the unit sensors determine there is nothing on top of the unit.</p>
                    </div>
                    <div>
                        <h4 style={{color: "#C1001F"}}>What if I don’t like the spot I was assigned?</h4>
                        <p>Just tap “Switch Space” and you’ll get the next best available spot assigned immediately</p>
                    </div>
                </div>
            </div>
        </section>
    ) 
}

export default Faq;